import {appsRoutes} from '@/modules/Apps/Routes/appsRoutes.js'
import {routesByName} from '@/modules/Shared/Helpers/routesHelper.js'
import {appsActions as actions} from "@/modules/Apps/Actions/appsActions";

const routes = routesByName(appsRoutes)

export default [
  {
    title: "apps.configurator.title",
    subtitle: "apps.configurator.subtitle",
    items: [
      {
        icon: "configurator",
        route: routes['Configurator']
      }
    ]
  },
  {
    title: "apps.messaging.title",
    subtitle: "apps.messaging.subtitle",
    items: [
      {
        icon: "pushmessages",
        route: routes['push-messages']
      },
      {
        icon: "geofences",
        route: routes['geofences']
      }
    ]
  },
  {
    title: "apps.banners.title",
    subtitle: "apps.banners.subtitle",
    items: [
      {
        icon: "banners",
        route: routes['banners']
      },
      {
        icon: "interstitial_banner",
        route: routes['banners-interstitials']
      }
    ]
  },
  {
    title: "apps.customerdata.title",
    subtitle: "apps.customerdata.subtitle",
    items: [
      {
        icon: "registeredusers",
        route: routes['registeredusers'],
        features: ['is_ski_app']
      },
      {
        icon: "tracks",
        route: routes['app-tracks'],
        features: ['is_ski_app']
      },
      {
        icon: "photos",
        route: routes['app-photos'],
        features: ['is_ski_app']
      },
    ]
  },
  {
    title: "apps.settings.title",
    subtitle: "apps.settings.subtitle",
    items: [
      {
        icon: "updateapp",
        action: actions.updateapp
      },
      {
        icon: "contactphones",
        route: routes['contactphones']
      },
      {
        icon: "promoassets",
        route: routes['promoassets']
      }
    ]
  }
]
